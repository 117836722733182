import React from "react"
import { graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../layouts"
import SEO from "../components/seo"
import Teaser from "../components/teaser"
// import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const title = data.site.siteMetadata.title
    const description = data.site.siteMetadata.description
    const posts = data.allMarkdownRemark.edges
    
    return (
      <>
        <SEO title={`Kopfwelt — ${title}`} />
        <div className="grid grid-cols-8 gap-6">
          <div className="col-span-8 sm:col-span-6 sm:col-start-2 mb-10">
            <h1 className="font-mono leading-none text-5xl md:text-6xl lg:text-8xl">{description}</h1>
          </div>
          {posts.map(({ node }, index) => (
            <Teaser key={node.fields.slug} post={node} index={index} />
          ))}
        </div>
      </>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          ...TeaserFragment
        }
      }
    }
  }
`
